<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px">
    <main class="page-content">
      <Toast
        v-if="toastData.show"
        :type="toastData.type"
        :message="toastData.message"
        :show="toastData.show"
        :time="10000"
        @clearToast="clearToast"
      />
      <div class="container-fluid">
        <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
          <li class="ProximaNovaBold">
            Settings
          </li>
          <li class="ProximaNovaBold">
            Switch Users
          </li>
        </ul>
      </div>
      <div class="container-fluid m-0 p-0 mt-5">
        <div class="mx-5 text-right" style="font-size: small;">
          <div>
            {{ rowsPrePage * currentPage - 4 }} -
            {{ rowsPrePage * (currentPage - 1) + tableDataValues.items.length }}
            of {{ totalRows }}
          </div>
        </div>
        <div class="m-5">
          <w-table
            :tableProperties="tableDataValues"
            :noDataText="'No User found'"
            :customRowColor="true"
            :headingText="'Users'"
            :showHeading="true"
            @switchUser="switchUser"
          />
          <div class="d-flex" style="justify-content: end;">
            <w-pagination
              :currentPage="currentPage"
              :perPage="rowsPrePage"
              :totalRows="totalRows"
              @getSelectedPageEvent="getSelectedPageEvent($event)"
            />
          </div>
        </div>
      </div>
      <ConfirmationModel ref="confirmationBox" @continue="continueUser" />
    </main>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";
import { UserManagement } from "@/services/UserManagementService";
import Pagination from "@/widgets/Pagination.vue";
import Toast from "@/components/Toast/Toast.vue";
import ConfirmationModel from "@/components/Modal/ConfirmationModal.vue";
const userManagement = new UserManagement();
import { LoginServices } from "@/services/LoginServices.js";
const loginServices = new LoginServices();
export default {
  components: {
    "w-table": DataTable,
    "w-pagination": Pagination,
    Toast,
    ConfirmationModel,
  },
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      rowsPrePage: 5,
      totalRows: 0,
      currentPage: 1,
      tableDataValues: {
        fields: [
          {
            key: "userName",
            label: "Full Name",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "email",
            label: "Email Id",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "role",
            label: "User Role",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          // {
          //   key: "createdDate",
          //   label: "Created Date",
          //   thClass: "thClassLeft",
          //   tdClass: "text-left",
          //   sortable: true,
          // },
          {
            key: "toolsAccess",
            label: "Tools",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          // {
          //   key: "verification",
          //   label: "Verification",
          //   thClass: "thClassLeft",
          //   tdClass: "text-left",
          //   sortable: false,
          // },
          {
            key: "status",
            label: "Status",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "assignedBrands",
            label: "Brand",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "switchUser",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      switchUserData: "",
    };
  },
  mounted() {},
  beforeMount() {
    if (sessionStorage.getItem("parentEmail") === null) {
      this.$router.go(-1);
    } else {
      if (
        sessionStorage.getItem("parentEmail").split("@")[1] !== "datapoem.com"
      ) {
        this.$router.go(-1);
      }
    }
  },
  methods: {
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    continueUser() {
      sessionStorage.setItem("userName", this.switchUserData.userName);
      sessionStorage.setItem("clientId", this.switchUserData.email);
      sessionStorage.setItem("subId", this.switchUserData.subscriptionId);
      sessionStorage.setItem("userId", this.switchUserData.id);
      sessionStorage.setItem("Role", "ADMIN");
      loginServices
        .getClientType(this.switchUserData.subscriptionId)
        .then((res) => {
          sessionStorage.setItem("clientType", res.clientType);
          sessionStorage.setItem("agencyId", res.agencyId);
          sessionStorage.setItem(
            "tools",
            JSON.stringify(res.subscriberToolType)
          );
          this.$router.push("/welcome");
        });
    },
    switchUser(e) {
      this.switchUserData = e;
      this.$refs.confirmationBox.open({
        title: "Your account will be logged in as " + e.userName,
      });
    },
    getSelectedPageEvent(event) {
      if (event != this.currentPage) {
        this.currentPage = event;
        this.getUserList();
      }
    },
    getUserList() {
      userManagement
        .getSwitchUserList(
          sessionStorage.getItem("parentEmail"),
          this.currentPage,
          this.rowsPrePage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.response.content;
          this.totalRows = res.response.totalElements;
        });
    },
  },
  created() {
    this.getUserList();
  },
};
</script>

<style></style>
